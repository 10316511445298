import React, { useState } from "react";
import { MyPaginate } from "./pagination.style";
import { navigate } from "gatsby";

export default function PageButtons({ pageContext }) {
  const { numberOfPages, pageNumber } = pageContext;
  const [pageSelected, setPageSelected] = useState(pageNumber);

  const handlePageClick = (event) => {
    setPageSelected(event.selected);
    if (event.selected === 0) {
      navigate(`/blog`);
      return;
    }
    navigate(`/blog/${event.selected + 1}`);
  };

  return (
    <MyPaginate
      breakLabel="..."
      nextLabel="Next"
      forcePage={pageSelected}
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={numberOfPages}
      previousLabel="Previous"
      renderOnZeroPageCount={() => <div>1</div>}
    />
  );
}
