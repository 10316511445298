import styled from "styled-components";
import ReactPaginate from "react-paginate";

export const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
  className: "kaio-pagination",
})`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  li a {
    font-weight: 600;
    color: rgba(1, 10, 64, 1);
    cursor: pointer;
    margin: 0 10px;
    padding: 5px 14px;
    border-radius: 0.25rem;
    font-size: 14px;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: rgba(31, 109, 198, 0.6);
    border-color: transparent;
    ${"" /* color: white; */}
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
