import React from "react";
import Layout from "../components/Layout/Layout";
import Container from "common/components/UI/Container";
import {
  ContentBlog,
  BlogList,
  ContentBlogLeft,
  BlogImage,
  TitleBlog,
  BlogTextContent,
  PostDescription,
  Author,
} from "../containers/WebAppCreative/webAppCreative.style";
import Heading from "common/components/Heading";
import { graphql, Link } from "gatsby";
import { get } from "lodash";
import PageButtons from "../components/Pagination/Pagination";
import { useI18next } from "gatsby-plugin-react-i18next";
import CallToAction from "containers/WebAppCreative/CallToAction";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

export default function Blog({ data, pageContext }) {
  const { language } = useI18next();
  const listPosts = get(data, "allStrapiNewsfeed.nodes", []).filter(
    (item) => item.locale === language
  );
  return (
    <Layout title="Blog">
      <Container width="1400px">
        <div style={{ minHeight: "100vh", position: "relative" }}>
          <ContentBlog>
            <Heading as="h2" content="Blog" />
            {listPosts?.map((item) => (
              <BlogList key={item.id}>
                <ContentBlogLeft>
                  <BlogImage>
                    <GatsbyImage
                      image={getImage(item?.card?.image?.localFile)}
                      alt={item?.card?.image?.alter}
                      style={{ borderRadius: "8px" }}
                    />
                  </BlogImage>
                  <BlogTextContent>
                    <div>
                      <Link
                        to={`${
                          language === "vi"
                            ? `/${item?.card?.slug}`
                            : `/en/${item?.card?.slug}`
                        }`}
                      >
                        <TitleBlog>{item?.card?.title}</TitleBlog>
                      </Link>
                      <PostDescription>
                        {!!item?.contents?.data?.contents
                          ? parse(item?.contents?.data?.contents?.slice(0, 250))
                          : ""}
                      </PostDescription>
                    </div>
                    <Author>
                      <GatsbyImage
                        className="avatar-author"
                        image={getImage(
                          get(item, "author.authorAvatar.localFile", "")
                        )}
                        alt={get(
                          item,
                          "author.authorAvatar.alternativeText",
                          ""
                        )}
                      />
                      <div className="author-info">
                        <span className="author-name">
                          {get(item, "author.authorName", "")}
                        </span>
                        <span className="time-public">
                          {get(item, "card.timePublic", "")}
                        </span>
                      </div>
                    </Author>
                  </BlogTextContent>
                </ContentBlogLeft>
              </BlogList>
            ))}
            <div className="pagination-wrapper">
              <PageButtons pageContext={pageContext} />
            </div>
          </ContentBlog>
        </div>
        <CallToAction lang={language} />
      </Container>
    </Layout>
  );
}
export const query = graphql`
  query BlogQuery($skip: Int, $limit: Int) {
    allStrapiNewsfeed(limit: $limit, skip: $skip) {
      nodes {
        id
        card {
          id
          title
          slug
          timePublic(formatString: "MMMM DD,yyyy")
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  placeholder: BLURRED
                  pngOptions: { compressionSpeed: 10, quality: 10 }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        contents {
          data {
            contents
          }
        }
        locale
        author {
          authorName
          authorAvatar {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  placeholder: BLURRED
                  pngOptions: { compressionSpeed: 10, quality: 10 }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;
